import clsx from 'clsx';
import { useState } from 'react';
import styles from './Reporter.module.scss';

export type TMobileReportResult = {
  residential_coverage: boolean;
  commercial_coverage: boolean;
};

export type AddressCoverageReportResult = {
  url: string;
  address: string;
  tmobile_report: TMobileReportResult;
  att_report: boolean;
  is_blacklisted: boolean;
  longitude: number;
  latitude: number;
  active_neighbors_count: number;
  distance_to_closest_blacklisted_address: null | number;
  street: string;
  city: string;
  state: string;
  zipCode: string;
};

function getCoverageStyle(hasCoverage: boolean) {
  return hasCoverage
    ? clsx(styles.reportCoverage)
    : clsx(styles.reportNoCoverage);
}

function RecommendedAction({
  report,
}: {
  report: AddressCoverageReportResult;
}) {
  const TMobileAvailable =
    report.tmobile_report.commercial_coverage ||
    report.tmobile_report.residential_coverage;
  const ATTAvailable = report.att_report;
  const noCoverage = !TMobileAvailable && !ATTAvailable;
  const checkCellMapper = !TMobileAvailable;

  if (report.is_blacklisted) {
    return (
      <div
        className={clsx(styles.reportRecommendation, styles.reportNoCoverage)}
      >
        Flagged
      </div>
    );
  }

  if (noCoverage) {
    return (
      <div
        className={clsx(styles.reportRecommendation, styles.reportNoCoverage)}
      >
        No Coverage
      </div>
    );
  }

  if (checkCellMapper) {
    return (
      <div className={clsx(styles.reportRecommendation, styles.reportUnknown)}>
        Ask Customer / Check Cellmapper
      </div>
    );
  }

  if (TMobileAvailable) {
    return (
      <div className={clsx(styles.reportRecommendation, styles.reportCoverage)}>
        T-Mobile
      </div>
    );
  }

  return null;
}

function BlacklistedAddressWarning({
  isBlacklisted,
}: {
  isBlacklisted: boolean;
}) {
  if (!isBlacklisted) {
    return null;
  }
  return (
    <span className={clsx(styles.reportBlacklisted)}>
      Address marked as NOT COVERED
    </span>
  );
}

function BlacklistedNeighborReport({ distance }: { distance: number | null }) {
  if (!distance) {
    return <span>No record of cancelled/no-covered addresses</span>;
  }

  const distanceInKm = (distance / 1000).toFixed(2);
  const distanceInMiles = (distance / 1609).toFixed(2);

  return (
    <span>
      No-covered neighbor around {distanceInKm}Km ({distanceInMiles}Mi) from
      this address
    </span>
  );
}

function ActiveSubscribersReport({ amount }: { amount: number }) {
  if (!amount) {
    return <span>No record of active subscribers in the area</span>;
  }
  return <span>{amount} active subscribers</span>;
}

function NeighborhoodReport({
  report,
}: {
  report: AddressCoverageReportResult;
}) {
  return (
    <div>
      <h3 className={clsx(styles.reportProvider)}>
        Customers in the area (15Km/10mile radius)
      </h3>
      <ul>
        <li>
          <ActiveSubscribersReport amount={report.active_neighbors_count} />
        </li>
        <li>
          <BlacklistedNeighborReport
            distance={report.distance_to_closest_blacklisted_address}
          />
        </li>
      </ul>
    </div>
  );
}

function AttReport({ hasCoverage }: { hasCoverage: boolean }) {
  return (
    <div>
      <h3 className={clsx(styles.reportProvider)}>AT&T</h3>
      <ul>
        <li className={getCoverageStyle(hasCoverage)}>
          4G coverage: {hasCoverage ? '✅' : '✗'}
        </li>
      </ul>
    </div>
  );
}

function TMobileReport({
  tmobile_report,
}: {
  tmobile_report: TMobileReportResult;
}) {
  return (
    <div className={clsx(styles.reportProvider)}>
      <h3 className={clsx(styles.reportProvider)}>T-Mobile</h3>
      <ul>
        <li className={getCoverageStyle(tmobile_report.residential_coverage)}>
          Residential Area Coverage?{' '}
          {tmobile_report.residential_coverage ? '✅' : '✗'}
        </li>
        <li className={getCoverageStyle(tmobile_report.commercial_coverage)}>
          Commercial Area coverage?{' '}
          {tmobile_report.commercial_coverage ? '✅' : '✗'}
        </li>
      </ul>
    </div>
  );
}

function CellMapperLinks({ report }: { report: AddressCoverageReportResult }) {
  const ATT_MNC = '410';
  const TMOBILE_MNC = '260';

  const baseUrl = new URL('https://www.cellmapper.net/map');

  baseUrl.searchParams.append('MCC', '310');
  baseUrl.searchParams.append('latitude', report.latitude.toString());
  baseUrl.searchParams.append('longitude', report.longitude.toString());
  baseUrl.searchParams.append('type', 'LTE');
  baseUrl.searchParams.append('zoom', '16');
  baseUrl.searchParams.append('showTowers', 'true');
  baseUrl.searchParams.append('showIcons', 'true');
  baseUrl.searchParams.append('showTowerLabels', 'true');
  baseUrl.searchParams.append('clusterEnabled', 'true');
  baseUrl.searchParams.append('tilesEnabled', 'false');
  baseUrl.searchParams.append('showOrphans', 'false');
  baseUrl.searchParams.append('showNoFrequencyOnly', 'false');
  baseUrl.searchParams.append('showFrequencyOnly', 'false');
  baseUrl.searchParams.append('showBandwidthOnly', 'false');
  baseUrl.searchParams.append('showHex', 'false');
  baseUrl.searchParams.append('showVerifiedOnly', 'true');
  baseUrl.searchParams.append('showBand', '0');
  baseUrl.searchParams.append('showSectorColours', 'true');
  baseUrl.searchParams.append('mapType', 'roadmap');
  baseUrl.searchParams.append('darkMode', 'false');

  const attUrl = new URL(baseUrl.toString());
  attUrl.searchParams.append('MNC', ATT_MNC);

  const tmobileUrl = new URL(baseUrl.toString());
  tmobileUrl.searchParams.append('MNC', TMOBILE_MNC);

  return (
    <div>
      <h3>CellMapper Links</h3>
      <p>
        Please do not forget to filter for frequency bands{' '}
        <strong>2/4/5/12</strong>, as they are the only bands compatible with
        our main device.
      </p>
      <ul className={clsx(styles.linkList)}>
        <li>
          <a href={tmobileUrl.toString()} target="_blank" rel="noreferrer">
            T-Mobile
          </a>
        </li>
        <li>
          <a href={attUrl.toString()} target="_blank" rel="noreferrer">
            AT&T
          </a>
        </li>
      </ul>
    </div>
  );
}

const plans = [
  'voice1a',
  'voice1a-annually',
  'voice1a_no_contract',
  'business-(starter)',
  'business-(pro)',
  'business-(growth)',
  'business-starter-annually',
  'business-pro-annually',
  'business-growth-annually',
];

function Checkout({ report }: { report: AddressCoverageReportResult }) {
  const [plan, setPlan] = useState(plans[0]);

  const checkoutURL = new URL('https://checkout.communityphone.org/set-params');

  checkoutURL.searchParams.append('plan', plan);
  checkoutURL.searchParams.append('street', report.street);
  checkoutURL.searchParams.append('city', report.city);
  checkoutURL.searchParams.append('state', report.state);
  checkoutURL.searchParams.append('zip', report.zipCode);

  return (
    <div>
      <h3 className={clsx(styles.reportProvider)}>Checkout</h3>
      <div className={clsx(styles.checkout)}>
        <p>
          Select a plan to visit the checkout page and see the final price
          including taxes and fees.
        </p>
        <select value={plan} onChange={e => setPlan(e.target.value)}>
          {plans.map(plan => (
            <option key={plan} value={plan}>
              {plan}
            </option>
          ))}
        </select>
        <a href={checkoutURL.toString()} target="_blank" rel="noreferrer">
          Open Checkout
        </a>
      </div>
    </div>
  );
}

export default function Reporter({
  report,
}: {
  report: AddressCoverageReportResult | null;
}) {
  return (
    report && (
      <div className={clsx(styles.report)}>
        <div className={styles.reportHeader}>{report.address}</div>
        <RecommendedAction report={report} />
        <BlacklistedAddressWarning isBlacklisted={report.is_blacklisted} />
        <TMobileReport tmobile_report={report.tmobile_report} />
        <AttReport hasCoverage={report.att_report} />
        <CellMapperLinks report={report} />
        <NeighborhoodReport report={report} />
        <Checkout report={report} />
      </div>
    )
  );
}
