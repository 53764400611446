// The bulk of this code is from the sample app
// provided by the use-places-autocomplete library:
// https://github.com/wellyshen/use-places-autocomplete/blob/master/app
//

import styles from './AddressInput.module.scss';
import type { ChangeEvent } from 'react';
import { KeyboardEvent, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

const acceptedKeys = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter'];

type AddressInputProps = {
  onSelect: (result: AddressInputResult) => void;
};

type AddressInputResult = {
  address: string;
  street: string;
  city: string;
  state: string;
  lat: string;
  lng: string;
  zipCode: string;
};

type Suggestion = google.maps.places.AutocompletePrediction;

let previousValue = '';

export default function AddressInput(params: AddressInputProps) {
  const { onSelect } = params;
  const [currIndex, setCurrIndex] = useState<number | null>(null);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: {
        country: 'us',
      },
    },
  });
  const hasSuggestions = status === 'OK';

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const ref = useOnclickOutside(dismissSuggestions);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    previousValue = e.target.value;
  };

  const handleSelect =
    ({ description }: Suggestion) =>
    async () => {
      setValue(description, false);
      dismissSuggestions();

      const [result] = await getGeocode({ address: description });
      const street = result.formatted_address.split(',')[0];
      const city = getCity(result);
      const state = getState(result);
      const zipCode = getZipCode(result, false);
      const { lat, lng } = getLatLng(result);

      if (!zipCode) return null;

      onSelect({
        address: description,
        street,
        city,
        state,
        lat: lat.toString(),
        lng: lng.toString(),
        zipCode,
      });
    };

  const handleEnter = (idx: number) => () => {
    setCurrIndex(idx);
  };

  const handleLeave = () => {
    setCurrIndex(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (e.key === 'Enter' || e.key === 'Escape') {
      dismissSuggestions();
      return;
    }

    let nextIndex: number | null;

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      nextIndex = currIndex ?? data.length;
      nextIndex = nextIndex && nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
    setValue(
      nextIndex && data[nextIndex]
        ? data[nextIndex].description
        : previousValue,
      false
    );
  };

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map((suggestion, idx: number) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={place_id}
          id={`ex-list-item-${idx}`}
          className={`${styles['list-item']} ${
            idx === currIndex ? `${styles['list-item-darken']}` : ''
          }`}
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          role="option"
          aria-selected={idx === currIndex}
        >
          <strong>{main_text}</strong>
          <small className={styles['sub-text']}>{secondary_text}</small>
        </li>
      );
    });

    return <>{suggestions}</>;
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.autocomplete}
        ref={ref}
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        aria-owns="ex-list-box"
        aria-haspopup="listbox"
        aria-expanded={hasSuggestions}
      >
        <input
          autoFocus
          className={styles.input}
          value={value}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          disabled={!ready}
          placeholder="e.g. 9959 Westwanda Drive, Beverly Hills"
          type="text"
          aria-autocomplete="list"
          aria-controls="ex-list-box"
          aria-activedescendant={
            currIndex !== null ? `ex-list-item-${currIndex}` : undefined
          }
        />
        {hasSuggestions && (
          <ul
            id="ex-list-box"
            className={styles['list-box']}
            onMouseLeave={handleLeave}
            role="listbox"
          >
            {renderSuggestions()}
          </ul>
        )}
      </div>
    </div>
  );
}

function getCity(result: google.maps.GeocoderResult) {
  const component = result.address_components.find(({ types }) =>
    types.includes('locality')
  );

  return component?.long_name ?? '';
}

function getState(result: google.maps.GeocoderResult) {
  const component = result.address_components.find(({ types }) =>
    types.includes('administrative_area_level_1')
  );

  return component?.short_name ?? '';
}
