import Head from 'next/head';
import React from 'react';
import styles from '../styles/Home.module.css';
import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

import AddressInput from '../components/AddressInput';
import Reporter from '../components/Reporter';
import type { CheckParams } from '../lib/check';

export default function Home() {
  const [params, setParams] = useState<CheckParams | null>(null);
  const [report, setReport] = useState(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    Sentry.setContext('address', params);
    setError(null);
    setReport(null);

    async function getCoverageReport(address: string): Promise<void> {
      const response = await fetch(
        'https://api.phone.community/coverage/reports',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query: address }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setReport({
          ...result,
          street: params?.street,
          city: params?.city,
          state: params?.state,
          zipCode: params?.zipCode,
        });
      } else {
        setError(await getErrorMessage(response));
      }
    }

    const formattedAddress = params?.address
      .replace(/, USA$/, '')
      .concat(` ${params?.zipCode}`);

    if (formattedAddress) {
      getCoverageReport(formattedAddress);
    }
  }, [params]);

  return (
    <>
      <Head>
        <title>Coverage Check for Community Phone</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main}>
        <AddressInput onSelect={setParams} />
        {error ? <Error error={error} /> : <Reporter report={report} />}
      </main>
    </>
  );
}

async function getErrorMessage(response: Response): Promise<string> {
  const defaultErrorMessage = 'Error getting coverage report';

  try {
    const json = await response.json();
    return json?.[0] || defaultErrorMessage;
  } catch (error) {
    return defaultErrorMessage;
  }
}

function Error({ error }: { error: string }) {
  return <div style={{ margin: '2rem', color: 'red' }}>{error}</div>;
}
